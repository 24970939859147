<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      large
      exact>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          :class="{'link--active': !item.disabled}"
          :disabled="item.disabled"
          @click="item.click()"
        >
          <v-icon
            v-if="item.icon"
            left>
            {{ item.icon }}
          </v-icon>
          {{ item.text.toUpperCase() }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        :readonly="isSubmitting"
        lazy-validation
        @submit.prevent="submit()">
        <v-row
          v-if="isFetching"
          class="fill-height"
          align-content="center"
          justify="center">
          <v-col
            class="subtitle-1 text-center"
            cols="12">
            {{ $t('app.loading') }}
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="primary"
              indeterminate
              rounded
              height="6"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="role"
          class="mx-lg-12">
          <v-col
            cols="12"
            lg="2">
            <text-field-label
              :label="$t('administrator.role.field.name.label')"
              required />
          </v-col>
          <v-col
            cols="12"
            lg="10">
            <v-text-field
              v-model="form.name"
              :placeholder="$t('administrator.role.field.name.placeholder')"
              :rules="rule.name"
              :error-messages="error.name"
              autofocus
              dense
              outlined
              required
              @input="markAsDirty($event)" />
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
          <template v-for="(availableRole, key) in setting.availableRoles">
            <v-col
              :key="`group-${key}`"
              cols="12"
              lg="2">
              <v-checkbox
                v-if="key !== 'access_scope'"
                :input-value="selectedAll[key]"
                :label="$t(`administrator.role.field.permission.${toVar(key)}`)"
                class="mt-1"
                hide-details
                @change="toggle(key, $event)"
              />
            </v-col>
            <v-col
              :key="`group-${key}-permission`"
              cols="12"
              lg="10">
              <v-row no-gutters>
                <template v-for="available in availableRole">
                  <v-col
                    v-if="available === 'access_scope'"
                    :key="`group-${key}-permission-label-${available}`"
                    cols="12"
                    lg="6">
                    <text-field-label :label="$t(`administrator.role.field.permission.${toVar(available)}`)" />
                  </v-col>
                  <v-col
                    :key="`group-${key}-permission-${available}`"
                    cols="12"
                    lg="6">
                    <v-select
                      v-if="available === 'access_scope'"
                      v-model="form[toVar(available)]"
                      :items="roleAccessScopeEnum.enumValues"
                      item-text="description"
                      item-value="name"
                      class="mt-3"
                      hide-details
                      dense
                      outlined
                      @change="markAsDirty($event)" />
                    <v-checkbox
                      v-else
                      v-model="form[toVar(available)]"
                      :label="$t(`administrator.role.field.permission.${toVar(available)}`)"
                      :true-value="true"
                      :false-value="false"
                      class="mt-1"
                      hide-details
                      @change="markAsDirty($event)" />
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-col
              :key="`divider-${key}`"
              class="pb-0"
              cols="12">
              <v-divider />
            </v-col>
          </template>
          <v-col
            cols="12"
            lg="10"
            offset-lg="2">
            <save-change-button
              :state="state"
              :label="$t('app.btn.save')"
              :disabled="isFetching || !isDirty || !valid"
              @click="submit()" />
            <v-btn
              color="primary"
              text
              @click="$emit('close')">
              {{ $t('app.btn.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import humps from 'humps'
import _ from 'lodash'
import { GET_ROLEACCESSSCOPE_ENUM, GET_ROLE, GET_SETTING_PERMISSION, UPDATE_ROLE } from '@/resources/graphql'

export default {
  apollo: {
    role: () => ({
      fetchPolicy: 'no-cache',
      query: GET_ROLE,
      variables () {
        return {
          id: this.itemRoleId
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
      },
      result ({ data: { role } }) {
        // set dynamic field permission from server
        Object.keys(role).forEach((key) => {
          if (key !== '__typename') { this.form[key] = role[key] }
        })
        this.setLoading(false)
      }
    }),
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_PERMISSION
    }),
    roleAccessScopeEnum: () => ({
      query: GET_ROLEACCESSSCOPE_ENUM,
      update: ({ __type }) => __type
    })
  },
  props: {
    itemRoleId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      roleAccessScopeEnum: {
        enumValues: []
      },
      form: {
        name: ''
      },
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        name: []
      },
      setting: {
        availableRoles: {}
      },
      breadcrumbs: [
        {
          text: this.$t('administrator.role.title'),
          disabled: false,
          icon: 'mdi-account-multiple-plus',
          click: () => this.$emit('close')
        },
        {
          text: this.$t('administrator.role.updateTitle'),
          disabled: true,
          click: () => {
          }
        }
      ]
    }
  },
  computed: {
    selectedAll () {
      const group = {}
      Object.keys(this.setting.availableRoles).forEach((key) => {
        group[key] = _.every(
          this.setting.availableRoles[key],
          (permission) => this.form[this.toVar(permission)]
        )
      })
      return group
    },
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  mounted () {
    this.setLoading(true)
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    toVar (value) {
      return humps.camelize(value)
    },
    toggle (key, value) {
      this.setting.availableRoles[key].forEach((permission) => {
        const field = this.toVar(permission)
        this.form = {
          ...this.form,
          [field]: value
        }
      })
      this.markAsDirty()
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        name: []
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_ROLE,
          variables: {
            input: {
              ...this.form,
              name: this.form.name,
              id: Number(this.itemRoleId)
            }
          }
        })

        if (res) {
          const { data: { updateRole } } = res
          if (updateRole.errors && Object.keys(updateRole.errors).length) {
            this.state = 'error'
            this.error = updateRole.errors
          } else {
            this.state = 'success'
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .link--active {
    color: var(--v-primary-base);
    cursor: pointer;
  }
</style>
