import { render, staticRenderFns } from "./UserDataTable.vue?vue&type=template&id=f9bb1dfc&scoped=true&"
import script from "./UserDataTable.vue?vue&type=script&lang=js&"
export * from "./UserDataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9bb1dfc",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VPagination,VProgressLinear,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f9bb1dfc')) {
      api.createRecord('f9bb1dfc', component.options)
    } else {
      api.reload('f9bb1dfc', component.options)
    }
    module.hot.accept("./UserDataTable.vue?vue&type=template&id=f9bb1dfc&scoped=true&", function () {
      api.rerender('f9bb1dfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Administrator/components/User/UserDataTable.vue"
export default component.exports