var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header-label", {
        attrs: {
          label: _vm.$t("administrator.header"),
          "prepend-icon": "$g_administrator"
        }
      }),
      _c(
        "v-tabs",
        {
          staticClass: "app-tab",
          attrs: { "show-arrows": "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tabs-slider", { attrs: { color: "primary" } }),
          _vm._l(_vm.tabs, function(t) {
            return [
              t.id !== "role" || _vm.permission.settingRole
                ? _c("v-tab", {
                    key: "user_tab-" + t.text,
                    domProps: { textContent: _vm._s(t.text) }
                  })
                : _vm._e()
            ]
          }),
          _c("v-spacer"),
          _vm.tab === 0
            ? _c("user-create", {
                on: {
                  submit: function($event) {
                    return _vm.$refs.UserDataTable.refetch()
                  }
                }
              })
            : _vm._e(),
          _vm.permission.settingRole && _vm.tab === 1
            ? _c("role-create", {
                on: {
                  submit: function($event) {
                    return _vm.$refs.RoleDataTable.refetch()
                  }
                }
              })
            : _vm._e()
        ],
        2
      ),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            { key: 0 },
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center mb-4"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-grow-1 font-weight-medium" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("administrator.user.title")) +
                                  " "
                              ),
                              _c("span", { staticClass: "primary--text" }, [
                                _vm._v(_vm._s(_vm.shopName))
                              ])
                            ]
                          )
                        ]
                      ),
                      _c("user-data-table", { ref: "UserDataTable" })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.permission.settingRole
            ? _c(
                "v-tab-item",
                { key: 1 },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [_c("role-data-table", { ref: "RoleDataTable" })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }