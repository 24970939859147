var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c(
        "div",
        { staticClass: "ma-2" },
        [
          _vm.loading
            ? _c("v-progress-circular", {
                staticClass: "mr-2",
                attrs: { size: "16", width: "2", indeterminate: "" }
              })
            : _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c("v-img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.icon && !_vm.isTimeout,
                        expression: "icon && !isTimeout"
                      }
                    ],
                    staticClass: "mt-1",
                    attrs: {
                      src: _vm.icon,
                      height: "16",
                      eager: "",
                      contain: ""
                    }
                  })
                ],
                1
              )
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 150,
            "offset-x": ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(tooltip) {
                              return [
                                _c(
                                  "div",
                                  _vm._g(
                                    _vm._b({}, "div", tooltip.attrs, false),
                                    tooltip.on
                                  ),
                                  [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              value: _vm.label,
                                              placeholder: _vm.$t(
                                                "administrator.user.field.storage.placeholder"
                                              ),
                                              "append-icon": "mdi-menu-down",
                                              dense: "",
                                              outlined: "",
                                              "hide-details": "",
                                              readonly: ""
                                            }
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [_vm._v(" " + _vm._s(_vm.tooltipSelectedItems) + " ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.menu,
            callback: function($$v) {
              _vm.menu = $$v
            },
            expression: "menu"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "px-3" },
            [
              _c("v-text-field", {
                staticClass: "pa-4 pb-1",
                attrs: {
                  placeholder: _vm.$t("app.form.search.placeholder"),
                  "prepend-inner-icon": "mdi-magnify",
                  autofocus: "",
                  "hide-details": "",
                  dense: "",
                  outlined: ""
                },
                model: {
                  value: _vm.query,
                  callback: function($$v) {
                    _vm.query = $$v
                  },
                  expression: "query"
                }
              }),
              _c(
                "v-radio-group",
                {
                  staticClass: "ma-0",
                  attrs: { value: _vm.accessAllStock, "hide-details": "" },
                  on: {
                    change: function($event) {
                      return _vm.updateAccessAllStock($event)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "h-38" },
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: _vm.$t(
                                    "administrator.user.field.storage.selectAll"
                                  ),
                                  value: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { staticClass: "h-38" },
                    [
                      _c(
                        "v-list-item-content",
                        { staticClass: "pa-0" },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: _vm.$t(
                                    "administrator.user.field.storage.selection"
                                  ),
                                  value: false
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "px-7" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "pa-0 mb-3" },
                    [
                      _c("v-virtual-scroll", {
                        attrs: {
                          items: _vm.selectItems,
                          height: "180",
                          "item-height": "36"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c("v-checkbox", {
                                      key: "checkbox-item-" + item.id,
                                      staticClass: "ma-0",
                                      attrs: {
                                        "input-value": _vm.selectedItems,
                                        label: item.name,
                                        value: item,
                                        "value-comparator": _vm.comparator,
                                        disabled: item.disabled,
                                        "hide-details": "",
                                        multiple: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.updateSelectedItems($event)
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }