var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "app-card pt-6", attrs: { tile: "" } },
    [
      _c(
        "div",
        { staticClass: "close-popup" },
        [
          _c(
            "v-btn",
            {
              attrs: { icon: "", small: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", { attrs: { small: "" } }, [_vm._v(" mdi-close ")])],
            1
          )
        ],
        1
      ),
      _c("v-card-title", { staticClass: "headline font-weight-regular" }, [
        _vm._v(" " + _vm._s(_vm.$t("app.dialog.remove.title")) + " ")
      ]),
      _c("v-card-text", [
        _vm.canRemove
          ? _c("div", [
              _vm._v(
                " " + _vm._s(_vm.$t("app.dialog.remove.description")) + " "
              )
            ])
          : _c("div", { staticClass: "error--text" }, [
              _vm._v(" " + _vm._s(_vm.canNotRemoveMessage) + " ")
            ])
      ]),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("app.btn.cancel")) + " ")]
          ),
          _vm.canRemove
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "error",
                    depressed: "",
                    rounded: "",
                    dark: !_vm.loading,
                    disabled: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm")
                    }
                  }
                },
                [
                  _vm.loading
                    ? _c("v-progress-circular", {
                        staticClass: "mr-2",
                        attrs: { size: "16", width: "2", indeterminate: "" }
                      })
                    : _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" mdi-delete-outline ")
                      ]),
                  _vm._v(" " + _vm._s(_vm.$t("app.btn.remove")) + " ")
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }