<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    scrollable
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon>
          mdi-square-edit-outline
        </v-icon>
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isReadonly"
      lazy-validation
      @submit.prevent="submit()">
      <v-card>
        <v-card-title>
          <title-label :label="$t('administrator.user.updateTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row
            v-if="isFetching"
            class="fill-height"
            align-content="center"
            justify="center">
            <v-col
              class="subtitle-1 text-center"
              cols="12">
              {{ $t('app.loading') }}
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="primary"
                indeterminate
                rounded
                height="6"
              />
            </v-col>
          </v-row>
          <v-row v-if="user">
            <v-col cols="12">
              <text-field-label
                :label="$t('administrator.user.field.email.label')"
                required />
              <v-text-field
                v-model="form.email"
                :placeholder="$t('administrator.user.field.email.placeholder')"
                :rules="rule.email"
                :error-messages="error.email"
                autofocus
                type="email"
                dense
                required
                outlined
                @input="markAsDirty($event)" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="isFetching || !isDirty || !valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { GET_USER, UPDATE_USER } from '@/resources/graphql'

export default {
  apollo: {
    user: () => ({
      skip: true,
      fetchPolicy: 'no-cache',
      query: GET_USER,
      variables () {
        return {
          id: this.itemUserId
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
      },
      result ({ data: { user } }) {
        this.form.email = user.email
      }
    })
  },
  props: {
    itemUserId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isFetching: false,
      valid: true,
      isDirty: false,
      state: 'ready',
      form: {
        email: ''
      },
      rule: {
        email: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => /.+@.+\..+/.test(v) || this.$t('app.validate.email')
        ]
      },
      error: {
        email: []
      }
    }
  },
  computed: {
    isReadonly () {
      return this.state === 'loading'
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    changeDialog (value) {
      if (this.state !== 'loading') {
        this.$apollo.queries.user.skip = !value
        if (value) {
          this.isDirty = false
          this.$apollo.queries.user.refresh()
        }
      }
    },
    markAsDirty () {
      this.isDirty = true
      this.state = 'ready'
      this.error = {
        email: []
      }
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            input: {
              id: Number(this.itemUserId),
              email: this.form.email
            }
          }
        })

        if (res) {
          const { data: { updateUser } } = res
          if (updateUser.errors && Object.keys(updateUser.errors).length) {
            this.state = 'error'
            this.error = updateUser.errors
          } else {
            this.state = 'success'
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>
