import { render, staticRenderFns } from "./UserRemove.vue?vue&type=template&id=d828fe94&scoped=true&"
import script from "./UserRemove.vue?vue&type=script&lang=js&"
export * from "./UserRemove.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d828fe94",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VDialog,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d828fe94')) {
      api.createRecord('d828fe94', component.options)
    } else {
      api.reload('d828fe94', component.options)
    }
    module.hot.accept("./UserRemove.vue?vue&type=template&id=d828fe94&scoped=true&", function () {
      api.rerender('d828fe94', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Administrator/components/User/UserRemove.vue"
export default component.exports