<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        rounded
        v-bind="attrs"
        v-on="on">
        <v-icon
          class="mr-2"
          small>
          $plus
        </v-icon>
        {{ $t("administrator.user.btn.create") }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isSubmitting"
      lazy-validation
      @submit.prevent="submit()">
      <v-card>
        <v-card-title>
          <title-label :label="$t('administrator.user.createTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <text-field-label
                :label="$t('administrator.user.field.email.label')"
                required />
              <v-text-field
                v-model="form.email"
                :placeholder="$t('administrator.user.field.email.placeholder')"
                :rules="rule.email"
                :error-messages="error.email"
                type="email"
                dense
                outlined
                required />
            </v-col>
            <v-col cols="12">
              <text-field-label
                :label="$t('administrator.user.field.password.label')"
                required />
              <v-text-field
                v-model="form.password"
                :placeholder="$t('administrator.user.field.password.placeholder')"
                :rules="rule.password"
                :error-messages="error.password"
                type="password"
                dense
                outlined
                required />
            </v-col>
            <v-col cols="12">
              <text-field-label
                :label="$t('administrator.user.field.role.label')"
                required />
              <v-select
                v-model="form.roleId"
                :items="roles.nodes"
                :placeholder="$t('administrator.user.field.role.placeholder')"
                :rules="rule.roleId"
                :error-messages="error.roleId"
                item-text="name"
                item-value="id"
                dense
                outlined />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="!valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { CREATE_USER, GET_ROLES } from '@/resources/graphql'

export default {
  apollo: {
    roles: () => ({
      fetchPolicy: 'no-cache',
      query: GET_ROLES,
      variables () {
        return {
          page: 1,
          perPage: 1000
        }
      }
    })
  },
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      roles: {
        nodes: []
      },
      form: {
        email: '',
        password: '',
        roleId: null
      },
      rule: {
        email: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => /.+@.+\..+/.test(v) || this.$t('app.validate.email')
        ],
        password: [
          (v) => !!v || this.$t('app.validate.required'),
          (v) => (v && v.length >= 6) || this.$t('app.validate.max', [6])
        ],
        roleId: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        email: [],
        password: [],
        roleId: []
      }
    }
  },
  computed: {
    ...mapGetters({
      userCount: 'Setting/userCount',
      maxUser: 'Setting/maxUser'
    }),
    isSubmitting () {
      return this.state === 'loading'
    },
    userTextLimit () {
      return `${ this.userCount }/${ this.maxUser }`
    }
  },
  methods: {
    changeDialog () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.state = 'ready'
    },
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_USER,
          variables: {
            input: {
              email: this.form.email,
              password: this.form.password,
              roleId: this.form.roleId
            }
          }
        })

        if (res) {
          const { data: { createUser } } = res
          if (createUser.errors && Object.keys(createUser.errors).length) {
            this.state = 'error'
            this.error = createUser.errors
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.$refs.form.reset()
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>
