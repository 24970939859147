var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-simple-table", {
        staticClass: "app-table",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("thead", [
                  _c(
                    "tr",
                    _vm._l(_vm.headers, function(header, i) {
                      return _c("th", { key: "th_user-" + i }, [
                        _vm._v(" " + _vm._s(header) + " ")
                      ])
                    }),
                    0
                  ),
                  _vm.isFetching
                    ? _c("tr", [
                        _c(
                          "th",
                          {
                            staticClass: "simple-table-loader",
                            attrs: { colspan: _vm.headers.length }
                          },
                          [
                            _c("v-progress-linear", {
                              attrs: {
                                colspan: _vm.headers.length,
                                height: "2",
                                indeterminate: "",
                                top: ""
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm.users.nodes.length === 0
                    ? _c("tr", [
                        _c("td", { attrs: { colspan: _vm.headers.length } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column justify-center py-16 text-center"
                            },
                            [
                              _c("text-field-label", {
                                staticClass: "mb-3 justify-center",
                                attrs: {
                                  label: _vm.$t("app.table.emptyMessage", [
                                    _vm.$t("administrator.user.title")
                                  ])
                                }
                              }),
                              _c(
                                "div",
                                [
                                  _c("user-create", {
                                    on: {
                                      submit: function($event) {
                                        return _vm.refetch()
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.users.nodes, function(user) {
                    return _c("tr", { key: "tb_user-" + user.id }, [
                      _c("td", [_vm._v(_vm._s(user.email))]),
                      _c(
                        "td",
                        [
                          _c("user-update", {
                            attrs: { "item-user-id": user.id }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("user-role-update", {
                            attrs: {
                              value: user.role.id,
                              "item-user-id": user.id
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("user-stock-update", {
                            attrs: {
                              value: {
                                accessAllStock: user.accessAllStock,
                                stocks: user.stocks
                              },
                              "item-user-id": user.id,
                              items: _vm.stocks.nodes
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "td",
                        [
                          _c("user-remove", {
                            attrs: { "item-user-id": user.id },
                            on: {
                              submit: function($event) {
                                return _vm.refetch()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c("v-pagination", {
            attrs: { length: _vm.users.pagesCount },
            model: {
              value: _vm.page,
              callback: function($$v) {
                _vm.page = $$v
              },
              expression: "page"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }