var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selected
    ? _c("role-update", {
        attrs: { "item-role-id": _vm.selected.id },
        on: {
          close: function($event) {
            return _vm.deselect($event)
          }
        }
      })
    : _c(
        "div",
        [
          _c("v-simple-table", {
            staticClass: "app-table",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c("thead", [
                      _c(
                        "tr",
                        _vm._l(_vm.headers, function(header, i) {
                          return _c("th", { key: "th_role-" + i }, [
                            _vm._v(" " + _vm._s(header) + " ")
                          ])
                        }),
                        0
                      ),
                      _vm.isFetching
                        ? _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "simple-table-loader",
                                attrs: { colspan: _vm.headers.length }
                              },
                              [
                                _c("v-progress-linear", {
                                  attrs: {
                                    colspan: _vm.headers.length,
                                    height: "2",
                                    indeterminate: "",
                                    top: ""
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm.roles.nodes.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: _vm.headers.length } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex flex-column justify-center py-16 text-center"
                                  },
                                  [
                                    _c("text-field-label", {
                                      staticClass: "mb-3 justify-center",
                                      attrs: {
                                        label: _vm.$t(
                                          "app.table.emptyMessage",
                                          [_vm.$t("administrator.role.title")]
                                        )
                                      }
                                    }),
                                    _c(
                                      "div",
                                      [
                                        _c("role-create", {
                                          on: {
                                            submit: function($event) {
                                              return _vm.refetch()
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.roles.nodes, function(item) {
                        return _c("tr", { key: "tb_user-" + item.id }, [
                          _c("td", [_vm._v(_vm._s(item.name))]),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.select(item)
                                      }
                                    }
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(" mdi-square-edit-outline ")
                                    ])
                                  ],
                                  1
                                ),
                                _c("role-remove", {
                                  attrs: {
                                    "item-role-id": item.id,
                                    "can-remove": !item.users.length
                                  },
                                  on: {
                                    submit: function($event) {
                                      return _vm.refetch()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ])
                      }),
                      0
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("v-pagination", {
                attrs: { length: _vm.roles.pagesCount },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }