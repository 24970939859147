<template>
  <role-update
    v-if="selected"
    :item-role-id="selected.id"
    @close="deselect($event)" />
  <div v-else>
    <v-simple-table class="app-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="`th_role-${i}`">
              {{ header }}
            </th>
          </tr>
          <tr v-if="isFetching">
            <th
              :colspan="headers.length"
              class="simple-table-loader">
              <v-progress-linear
                :colspan="headers.length"
                height="2"
                indeterminate
                top />
            </th>
          </tr>
          <tr v-else-if="roles.nodes.length === 0">
            <td :colspan="headers.length">
              <div class="d-flex flex-column justify-center py-16 text-center">
                <text-field-label
                  :label="$t('app.table.emptyMessage', [$t('administrator.role.title')])"
                  class="mb-3 justify-center" />
                <div>
                  <role-create @submit="refetch()" />
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in roles.nodes"
            :key="`tb_user-${item.id}`">
            <td>{{ item.name }}</td>
            <td>
              <div class="text-center">
                <v-btn
                  color="primary"
                  icon
                  @click="select(item)">
                  <v-icon>
                    mdi-square-edit-outline
                  </v-icon>
                </v-btn>
                <role-remove
                  :item-role-id="item.id"
                  :can-remove="!item.users.length"
                  @submit="refetch()" />
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="roles.pagesCount" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import RoleCreate from '@/views/Administrator/components/Role/RoleCreate.vue'
import RoleRemove from '@/views/Administrator/components/Role/RoleRemove.vue'
import RoleUpdate from '@/views/Administrator/components/Role/RoleUpdate.vue'
import { GET_ROLES } from '@/resources/graphql'

export default {
  apollo: {
    roles: () => ({
      fetchPolicy: 'no-cache',
      query: GET_ROLES,
      variables () {
        return {
          page: this.page,
          perPage: this.pageSize
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
        this.setLoading(isLoading)
      },
      result ({ data: { roles } }) {
        this.isFetching = false
        if (this.page > roles.pagesCount) {
          this.page -= 1
        }
      }
    })
  },
  components: {
    RoleCreate,
    RoleUpdate,
    RoleRemove
  },
  data () {
    return {
      page: 1,
      pageSize: 10,
      isFetching: true,
      headers: [this.$t('administrator.role.table.header.name'), ''],
      roles: {
        nodes: [],
        pagesCount: 1
      },
      selected: null
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'Notification/setLoading'
    }),
    refetch () {
      this.$apollo.queries.roles.refetch()
    },
    select (item) {
      this.selected = item
    },
    deselect () {
      this.selected = null
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
