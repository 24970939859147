<template>
  <div>
    <header-label
      :label="$t('administrator.header')"
      prepend-icon="$g_administrator" />
    <v-tabs
      v-model="tab"
      class="app-tab"
      show-arrows>
      <v-tabs-slider color="primary" />
      <template v-for="t in tabs">
        <v-tab
          v-if="t.id !== 'role' || permission.settingRole"
          :key="`user_tab-${t.text}`"
          v-text="t.text" />
      </template>
      <v-spacer />
      <user-create
        v-if="tab === 0"
        @submit="$refs.UserDataTable.refetch()" />
      <role-create
        v-if="permission.settingRole && tab === 1"
        @submit="$refs.RoleDataTable.refetch()" />
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="0">
        <v-card flat>
          <v-card-text>
            <div class="d-flex justify-space-between align-center mb-4">
              <div class="flex-grow-1 font-weight-medium">
                {{ $t('administrator.user.title') }} <span class="primary--text">{{ shopName }}</span>
              </div>
            </div>
            <user-data-table ref="UserDataTable" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-if="permission.settingRole"
        :key="1">
        <v-card
          flat>
          <v-card-text>
            <role-data-table ref="RoleDataTable" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserDataTable from '@/views/Administrator/components/User/UserDataTable.vue'
import UserCreate from '@/views/Administrator/components/User/UserCreate.vue'
import RoleDataTable from '@/views/Administrator/components/Role/RoleDataTable.vue'
import RoleCreate from '@/views/Administrator/components/Role/RoleCreate.vue'
import { GET_SETTING_USER } from '@/resources/graphql'

export default {
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_USER,
      result ({ data: { setting } }) {
        this.setShopName(setting.name)
        this.setMaxUser(setting.maxUsers)
      }
    })
  },
  name: 'Administrator',
  components: {
    UserDataTable,
    UserCreate,
    RoleDataTable,
    RoleCreate
  },
  data () {
    return {
      tab: 0,
      tabs: [
        {
          text: this.$t('administrator.user.tab'),
          id: 'user'
        },
        {
          text: this.$t('administrator.role.tab'),
          id: 'role'
        }
      ],
      setting: {}
    }
  },
  computed: {
    ...mapGetters({
      permission: 'Auth/permission',
      shopName: 'Setting/shopName'
    })
  },
  methods: {
    ...mapActions({
      setMaxUser: 'Setting/setMaxUser',
      setShopName: 'Setting/setShopName'
    })
  }
}
</script>

<style>

</style>

<style scoped>

</style>
