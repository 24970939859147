<template>
  <div>
    <v-simple-table class="app-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="(header, i) in headers"
              :key="`th_user-${i}`">
              {{ header }}
            </th>
          </tr>
          <tr v-if="isFetching">
            <th
              :colspan="headers.length"
              class="simple-table-loader">
              <v-progress-linear
                :colspan="headers.length"
                height="2"
                indeterminate
                top />
            </th>
          </tr>
          <tr v-else-if="users.nodes.length === 0">
            <td :colspan="headers.length">
              <div class="d-flex flex-column justify-center py-16 text-center">
                <text-field-label
                  :label="$t('app.table.emptyMessage', [$t('administrator.user.title')])"
                  class="mb-3 justify-center" />
                <div>
                  <user-create @submit="refetch()" />
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in users.nodes"
            :key="`tb_user-${user.id}`">
            <td>{{ user.email }}</td>
            <td>
              <user-update :item-user-id="user.id" />
            </td>
            <td>
              <user-role-update
                :value="user.role.id"
                :item-user-id="user.id" />
            </td>
            <td>
              <user-stock-update
                :value="{ accessAllStock: user.accessAllStock, stocks: user.stocks }"
                :item-user-id="user.id"
                :items="stocks.nodes" />
            </td>
            <td>
              <user-remove
                :item-user-id="user.id"
                @submit="refetch()" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="users.pagesCount"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UserCreate from '@/views/Administrator/components/User/UserCreate.vue'
import UserUpdate from '@/views/Administrator/components/User/UserUpdate.vue'
import UserRemove from '@/views/Administrator/components/User/UserRemove.vue'
import UserRoleUpdate from '@/views/Administrator/components/User/UserRoleUpdate.vue'
import UserStockUpdate from '@/views/Administrator/components/User/UserStockUpdate.vue'
import { GET_USERS, GET_STOCKS } from '@/resources/graphql'

export default {
  apollo: {
    users: () => ({
      fetchPolicy: 'no-cache',
      query: GET_USERS,
      variables () {
        return {
          page: this.page,
          perPage: this.pageSize
        }
      },
      watchLoading (isLoading) {
        this.isFetching = isLoading
        this.setLoading(isLoading)
      },
      result ({ data: { users } }) {
        this.isFetching = false
        this.setUserCount(users.nodesCount)
        if (this.page > users.pagesCount) {
          this.page -= 1
        }
      }
    }),
    stocks: () => ({
      fetchPolicy: 'no-cache',
      query: GET_STOCKS,
      variables () {
        return {
          page: 1,
          perPage: 1000
        }
      }
    })
  },
  components: {
    UserCreate,
    UserRoleUpdate,
    UserStockUpdate,
    UserUpdate,
    UserRemove
  },
  data () {
    return ({
      page: 1,
      pageSize: 10,
      isFetching: true,
      headers: [
        this.$t('administrator.user.table.header.email'),
        '',
        this.$t('administrator.user.table.header.role'),
        this.$t('administrator.user.table.header.storage'),
        ''
      ],
      users: {
        nodes: []
      },
      stocks: {
        nodes: []
      }
    })
  },
  methods: {
    ...mapActions({
      setUserCount: 'Setting/setUserCount',
      setLoading: 'Notification/setLoading'
    }),
    refetch () {
      this.$apollo.queries.users.refetch()
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
