var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-form", {
    attrs: {
      value: _vm.value,
      placeholder: _vm.$t("administrator.user.field.role.placeholder"),
      items: _vm.roles.nodes,
      state: _vm.state,
      "item-text": "name",
      "item-value": "id",
      "hide-details": "",
      dense: "",
      outlined: ""
    },
    on: {
      input: function($event) {
        return _vm.submit($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }