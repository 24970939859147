<template>
  <select-form
    :value="value"
    :placeholder="$t('administrator.user.field.role.placeholder')"
    :items="roles.nodes"
    :state="state"
    item-text="name"
    item-value="id"
    hide-details
    dense
    outlined
    @input="submit($event)" />
</template>

<script>
import { GET_ROLES, UPDATE_USER } from '@/resources/graphql'

export default {
  apollo: {
    roles: () => ({
      query: GET_ROLES
    })
  },
  props: {
    value: {
      type: [String, Number],
      default: () => ''
    },
    itemUserId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      state: 'ready',
      roles: {
        nodes: []
      }
    }
  },
  methods: {
    async submit (value) {
      this.state = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          input: {
            id: this.itemUserId,
            roleId: value
          }
        }
      }).catch(() => {
        this.state = 'error'
      })
      if (res) {
        const { data: { updateUser } } = res
        if (updateUser.errors && Object.keys(updateUser.errors).length) {
          this.state = 'error'
        } else {
          this.state = 'success'
        }
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>
  .input-style {
    width: 420px;
  }
</style>
