import { render, staticRenderFns } from "./Administrator.vue?vue&type=template&id=eefc05be&scoped=true&"
import script from "./Administrator.vue?vue&type=script&lang=js&"
export * from "./Administrator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eefc05be",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VSpacer,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eefc05be')) {
      api.createRecord('eefc05be', component.options)
    } else {
      api.reload('eefc05be', component.options)
    }
    module.hot.accept("./Administrator.vue?vue&type=template&id=eefc05be&scoped=true&", function () {
      api.rerender('eefc05be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Administrator/Administrator.vue"
export default component.exports