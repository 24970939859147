<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        icon
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </template>
    <alert-remove-card
      :loading="isLoading"
      :can-remove="canRemove"
      :can-not-remove-message="$t('administrator.role.dialog.canNotRemoveMessage')"
      @confirm="submit()"
      @close="closeDialog()" />
  </v-dialog>
</template>

<script>
import { DELETE_ROLE } from '@/resources/graphql'
import AlertRemoveCard from '@/components/Card/AlertRemoveCard.vue'

export default {
  components: {
    AlertRemoveCard
  },
  props: {
    canRemove: {
      type: Boolean,
      default: true
    },
    itemRoleId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isLoading: false
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.canRemove) {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: DELETE_ROLE,
          variables: {
            input: {
              id: Number(this.itemRoleId)
            }
          }
        })
        this.isLoading = false
        this.$emit('submit')
        this.closeDialog()
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
