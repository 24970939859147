<template>
  <div class="d-flex">
    <div class="ma-2">
      <v-progress-circular
        v-if="loading"
        class="mr-2"
        size="16"
        width="2"
        indeterminate />
      <transition
        v-else
        name="fade">
        <v-img
          v-show="icon && !isTimeout"
          :src="icon"
          class="mt-1"
          height="16"
          eager
          contain />
      </transition>
    </div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="150"
      offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="tooltip">
            <div
              v-bind="tooltip.attrs"
              v-on="tooltip.on">
              <v-text-field
                :value="label"
                :placeholder="$t('administrator.user.field.storage.placeholder')"
                append-icon="mdi-menu-down"
                dense
                outlined
                hide-details
                readonly
                v-bind="attrs"
                v-on="on" />
            </div>
          </template>
          {{ tooltipSelectedItems }}
        </v-tooltip>
      </template>
      <v-card class="px-3">
        <v-text-field
          v-model="query"
          :placeholder="$t('app.form.search.placeholder')"
          class="pa-4 pb-1"
          prepend-inner-icon="mdi-magnify"
          autofocus
          hide-details
          dense
          outlined />
        <v-radio-group
          :value="accessAllStock"
          class="ma-0"
          hide-details
          @change="updateAccessAllStock($event)">
          <v-list-item class="h-38">
            <v-list-item-content class="pa-0">
              <v-list-item-title>
                <v-radio
                  :label="$t('administrator.user.field.storage.selectAll')"
                  :value="true"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="h-38">
            <v-list-item-content class="pa-0">
              <v-list-item-title>
                <v-radio
                  :label="$t('administrator.user.field.storage.selection')"
                  :value="false"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-radio-group>
        <v-list-item class="px-7">
          <v-list-item-content class="pa-0 mb-3">
            <v-virtual-scroll
              :items="selectItems"
              height="180"
              item-height="36">
              <template v-slot:default="{ item }">
                <v-list-item>
                  <v-checkbox
                    :key="`checkbox-item-${item.id}`"
                    :input-value="selectedItems"
                    :label="item.name"
                    :value="item"
                    :value-comparator="comparator"
                    :disabled="item.disabled"
                    class="ma-0"
                    hide-details
                    multiple
                    @change="updateSelectedItems($event)"
                  />
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import _ from 'lodash'
import { UPDATE_USER } from '@/resources/graphql'

export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          accessAllStock: false,
          stocks: []
        })
      },
      items: {
        type: Array,
        default: () => []
      },
      itemUserId: {
        type: [String, Number],
        required: true
      }
    },
    data () {
      return {
        state: 'ready',
        menu: false,
        query: '',
        timeOut: null,
        isTimeout: false,
        accessAllStock: this.value.accessAllStock,
        selectedItems: this.value.stocks
      }
    },
    computed: {
      label () {
        if (this.accessAllStock) {
          return this.$t('administrator.user.field.storage.selectAll')
        }
        let label = ''
        if (this.selectedItems[0]) {
          label += this.selectedItems[0].name
        }
        if (this.selectedItems[1]) {
          label += ` (+${ this.selectedItems.length - 1 } others)`
        }
        return label
      },
      tooltipSelectedItems () {
        return this.selectedItems.map((item) => item.name).join(', ')
      },
      loading () {
        return this.state === 'loading'
      },
      icon () {
        switch (this.state) {
          case 'success':
            return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
          case 'error':
            return `${process.env.VUE_APP_ROUTE_PREFIX}imgs/icon/correct_icon.gif?fid=${ Math.random(10000) }`
          default: return null
        }
      },
      color () {
        switch (this.state) {
          case 'ready':
          case 'loading':
            return 'primary'
          case 'success':
            return 'success'
          case 'error':
            return 'error'
          default: return 'primary'
        }
      },
      selectItems () {
        return this.items
          .filter((item) => item.name.includes(this.query))
          .map((item) => ({ ...item, disabled: this.accessAllStock }))
}
    },
    watch: {
      state (value) {
        if (value === 'success') {
          clearTimeout(this.timeOut)
          this.timeOut = setTimeout(() => {
            this.isTimeout = true
          }, 3000)
        } else {
          this.isTimeout = false
        }
      }
    },
    methods: {
      comparator (item, value) {
        return item.id === value.id
      },
      updateSelectedItems (value) {
        this.selectedItems = value
        if (!this.accessAllStock) {
          this.debounceSubmit()
        }
      },
      updateAccessAllStock (event) {
        this.accessAllStock = event
        if (this.accessAllStock) {
          this.selectedItems = this.items.slice()
        } else {
          this.selectedItems = this.selectedItems.slice()
        }
        this.debounceSubmit()
      },
      debounceSubmit: _.debounce(function () {
        this.submit(this.accessAllStock, this.selectedItems)
      }, 1500),
      async submit (accessAllStock, selectedItems) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            input: {
              id: this.itemUserId,
              accessAllStock,
              allowedStocks: selectedItems.map((item) => item.id)
            }
          }
        }).catch(() => {
          this.state = 'error'
        })
        if (res) {
          const { data: { updateUser } } = res
          if (updateUser.errors && Object.keys(updateUser.errors).length) {
            this.state = 'error'
          } else {
            this.state = 'success'
          }
        }
      }
    }
  }
</script>

<style>
.v-list-item.h-38 {
  min-height: 38px;
}
</style>

<style scoped>

</style>
