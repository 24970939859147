<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    @input="changeDialog($event)">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        depressed
        rounded
        v-bind="attrs"
        v-on="on">
        <v-icon
          left
          small>
          $plus
        </v-icon>
        {{ $t('administrator.role.btn.create') }}
      </v-btn>
    </template>
    <v-form
      ref="form"
      v-model="valid"
      :readonly="isSubmitting"
      lazy-validation
      @submit.prevent="submit()">
      <v-card>
        <v-card-title>
          <title-label :label="$t('administrator.role.createTitle')" />
          <v-spacer />
          <v-btn
            icon
            small
            @click="closeDialog()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <text-field-label
                :label="$t('administrator.role.field.name.label')"
                required />
              <v-text-field
                v-model="form.name"
                :placeholder="$t('administrator.role.field.name.placeholder')"
                :rules="rule.name"
                :error-messages="error.name"
                dense
                outlined
                required />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()">
            {{ $t('app.btn.cancel') }}
          </v-btn>
          <save-change-button
            :state="state"
            :label="$t('app.btn.save')"
            :disabled="!valid"
            @click="submit()" />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { CREATE_ROLE } from '@/resources/graphql'

export default {
  data () {
    return {
      dialog: false,
      valid: true,
      state: 'ready',
      form: {
        name: ''
      },
      rule: {
        name: [
          (v) => !!v || this.$t('app.validate.required')
        ]
      },
      error: {
        name: []
      }
    }
  },
  computed: {
    isSubmitting () {
      return this.state === 'loading'
    }
  },
  methods: {
    changeDialog () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.state = 'ready'
    },
    closeDialog () {
      this.dialog = false
    },
    async submit () {
      if (this.$refs.form.validate()) {
        this.state = 'loading'
        const res = await this.$apollo.mutate({
          mutation: CREATE_ROLE,
          variables: {
            input: {
              name: this.form.name
            }
          }
        })

        if (res) {
          const { data: { createRole } } = res
          if (createRole.errors && Object.keys(createRole.errors).length) {
            this.state = 'error'
            this.error = createRole.errors
          } else {
            this.state = 'success'
            this.$emit('submit')
            this.$refs.form.reset()
            this.closeDialog()
          }
        }
      }
    }
  }
}
</script>
