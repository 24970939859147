<template>
  <v-card
    class="app-card pt-6"
    tile>
    <div class="close-popup">
      <v-btn
        icon
        small
        @click="$emit('close')">
        <v-icon small>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-card-title class="headline font-weight-regular">
      {{ $t('app.dialog.remove.title') }}
    </v-card-title>
    <v-card-text>
      <div v-if="canRemove">
        {{ $t('app.dialog.remove.description') }}
      </div>
      <div
        v-else
        class="error--text">
        {{ canNotRemoveMessage }}
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="$emit('close')">
        {{ $t('app.btn.cancel') }}
      </v-btn>
      <v-btn
        v-if="canRemove"
        color="error"
        depressed
        rounded
        :dark="!loading"
        :disabled="loading"
        @click="$emit('confirm')">
        <v-progress-circular
          v-if="loading"
          class="mr-2"
          size="16"
          width="2"
          indeterminate />
        <v-icon
          v-else
          left>
          mdi-delete-outline
        </v-icon>
        {{ $t('app.btn.remove') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Boolean,
      default: true
    },
    canNotRemoveMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>

<style scoped>

</style>
