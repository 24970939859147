var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-breadcrumbs", {
        attrs: { items: _vm.breadcrumbs, large: "", exact: "" },
        scopedSlots: _vm._u([
          {
            key: "divider",
            fn: function() {
              return [_c("v-icon", [_vm._v("mdi-chevron-right")])]
            },
            proxy: true
          },
          {
            key: "item",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-breadcrumbs-item",
                  {
                    class: { "link--active": !item.disabled },
                    attrs: { disabled: item.disabled },
                    on: {
                      click: function($event) {
                        return item.click()
                      }
                    }
                  },
                  [
                    item.icon
                      ? _c("v-icon", { attrs: { left: "" } }, [
                          _vm._v(" " + _vm._s(item.icon) + " ")
                        ])
                      : _vm._e(),
                    _vm._v(" " + _vm._s(item.text.toUpperCase()) + " ")
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-container",
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { readonly: _vm.isSubmitting, "lazy-validation": "" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit()
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _vm.isFetching
                ? _c(
                    "v-row",
                    {
                      staticClass: "fill-height",
                      attrs: { "align-content": "center", justify: "center" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "subtitle-1 text-center",
                          attrs: { cols: "12" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("app.loading")) + " ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-progress-linear", {
                            attrs: {
                              color: "primary",
                              indeterminate: "",
                              rounded: "",
                              height: "6"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.role
                ? _c(
                    "v-row",
                    { staticClass: "mx-lg-12" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "2" } },
                        [
                          _c("text-field-label", {
                            attrs: {
                              label: _vm.$t(
                                "administrator.role.field.name.label"
                              ),
                              required: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: _vm.$t(
                                "administrator.role.field.name.placeholder"
                              ),
                              rules: _vm.rule.name,
                              "error-messages": _vm.error.name,
                              autofocus: "",
                              dense: "",
                              outlined: "",
                              required: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.markAsDirty($event)
                              }
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [_c("v-divider")],
                        1
                      ),
                      _vm._l(_vm.setting.availableRoles, function(
                        availableRole,
                        key
                      ) {
                        return [
                          _c(
                            "v-col",
                            {
                              key: "group-" + key,
                              attrs: { cols: "12", lg: "2" }
                            },
                            [
                              key !== "access_scope"
                                ? _c("v-checkbox", {
                                    staticClass: "mt-1",
                                    attrs: {
                                      "input-value": _vm.selectedAll[key],
                                      label: _vm.$t(
                                        "administrator.role.field.permission." +
                                          _vm.toVar(key)
                                      ),
                                      "hide-details": ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.toggle(key, $event)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              key: "group-" + key + "-permission",
                              attrs: { cols: "12", lg: "10" }
                            },
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _vm._l(availableRole, function(available) {
                                    return [
                                      available === "access_scope"
                                        ? _c(
                                            "v-col",
                                            {
                                              key:
                                                "group-" +
                                                key +
                                                "-permission-label-" +
                                                available,
                                              attrs: { cols: "12", lg: "6" }
                                            },
                                            [
                                              _c("text-field-label", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "administrator.role.field.permission." +
                                                      _vm.toVar(available)
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          key:
                                            "group-" +
                                            key +
                                            "-permission-" +
                                            available,
                                          attrs: { cols: "12", lg: "6" }
                                        },
                                        [
                                          available === "access_scope"
                                            ? _c("v-select", {
                                                staticClass: "mt-3",
                                                attrs: {
                                                  items:
                                                    _vm.roleAccessScopeEnum
                                                      .enumValues,
                                                  "item-text": "description",
                                                  "item-value": "name",
                                                  "hide-details": "",
                                                  dense: "",
                                                  outlined: ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.markAsDirty(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.form[
                                                      _vm.toVar(available)
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      _vm.toVar(available),
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form[toVar(available)]"
                                                }
                                              })
                                            : _c("v-checkbox", {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "administrator.role.field.permission." +
                                                      _vm.toVar(available)
                                                  ),
                                                  "true-value": true,
                                                  "false-value": false,
                                                  "hide-details": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.markAsDirty(
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.form[
                                                      _vm.toVar(available)
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      _vm.toVar(available),
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form[toVar(available)]"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              key: "divider-" + key,
                              staticClass: "pb-0",
                              attrs: { cols: "12" }
                            },
                            [_c("v-divider")],
                            1
                          )
                        ]
                      }),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "10", "offset-lg": "2" } },
                        [
                          _c("save-change-button", {
                            attrs: {
                              state: _vm.state,
                              label: _vm.$t("app.btn.save"),
                              disabled:
                                _vm.isFetching || !_vm.isDirty || !_vm.valid
                            },
                            on: {
                              click: function($event) {
                                return _vm.submit()
                              }
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("close")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("app.btn.cancel")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }